import Vue from 'vue'
import App from './App.vue'
/*import 'core-js'*/
// Only importing core features to reduce bundled file size
import 'core-js/features/promise';
import 'core-js/features/symbol'; 
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false
Vue.use(VueSmoothScroll)

new Vue({
  render: h => h(App),
}).$mount('#app')
