<template>
  <div id="technology" class="technology">
    <div class="content-container content-padding">
      <h2>Technology</h2>
      <p class="large-p">Technology I actively use and have experience with.</p>
      <div class="row pd-top-16px">
        <div class="tech-circle">
          <div class="tech-circle-img nodejs-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img reactjs-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img socketio-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img pm2-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img mssql-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img mysql-logo"></div> 
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img git-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img sourcetree-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img sublimetext-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img office365-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img teams-logo"></div>
        </div>
        <div class="tech-circle">   
          <div class="tech-circle-img vmware-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img ubuntu-logo"></div>
        </div>
        <div class="tech-circle">
          <div class="tech-circle-img windows10-logo"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Technology'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .technology {
    width: 100%;
    background-color: #42525c;
  }

  .tech-circle {
    display: inline-block;
    background-color: white;
    width: 150px;
    height: 150px;
    -webkit-border-radius: 75px;
    -moz-border-radius: 75px;
    border-radius: 75px; 
    margin: 10px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
  }

  .nodejs-logo {
    background: url(/img/technology/nodejs.png) no-repeat center;
  }

  .reactjs-logo {
    background: url(/img/technology/react-js.png) no-repeat center;
  }

  .socketio-logo {
    background: url(/img/technology/socketio.png) no-repeat center;
  }

  .sublimetext-logo {
    background: url(/img/technology/sublimetext.png) no-repeat center;
  }

  .vmware-logo {
    background: url(/img/technology/vmware.png) no-repeat center;
  }

  .pm2-logo {
    background: url(/img/technology/pm2.png) no-repeat center;
  }

  .mssql-logo {
    background: url(/img/technology/mssql.svg) no-repeat center;
  }

  .sourcetree-logo {
    background: url(/img/technology/sourcetree.svg) no-repeat center;
  }

  .office365-logo {
    background: url(/img/technology/office365.svg) no-repeat center;
  }

  .git-logo {
    background: url(/img/technology/git.png) no-repeat center;
    background-size: 70% !important;
  }

  .mysql-logo {
    background: url(/img/technology/mysql.png) no-repeat center;
  }

  .ubuntu-logo {
    background: url(/img/technology/ubuntu.png) no-repeat center;
  }

  .windows10-logo {
    background: url(/img/technology/windows-10.svg) no-repeat center;
  }

  .teams-logo {
    background: url(/img/technology/microsoft-teams.png) no-repeat center;
  }

  .tech-circle-img {
    margin: 25px;
    height: 100px;
    background-size: 100%;
    background-position: center;
  }
</style>