<template>
  <div id="contact" class="contact">
    <div class="content-container content-padding">
      <h2>Contact</h2>
      <p class="large-p">If you have an enquiry or just want to get in touch you can send me an email below.</p>
      <a class="email-button" href="mailto:contact@corybeams.com"><i class="email-icon"></i><span>Email</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .contact {
    width: 100%;
    color: #273036;
  }

  .email-button {
    display: inline-block;
    font-size: 1.5em;
    border-radius: 30px;
    padding: 5px;
    background-color: #5234eb;
    color: white;
    vertical-align: middle;
    line-height: 30px;
    white-space:nowrap;
    padding-left: 20px;
    padding-right: 20px;
    border: 3px solid #5234eb;
  }

  .email-button span{
    vertical-align: middle;
  }

  .email-button:hover {
    background-color: transparent;
    color: #5234eb;
    opacity: 1;
  }

  .email-button:hover .email-icon {
    background: url(/img/icons/email-hover.png) no-repeat center;
    background-size: 100%;
    background-position: center;
    vertical-align: middle;
  }

  .email-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(/img/icons/email.png) no-repeat center;
    background-size: 100%;
    background-position: center;
    vertical-align: middle;
    margin-right: 15px;
  }
</style>