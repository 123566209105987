<template>
  <div class="footer">
    <div class="content-container">
      <div class="row content-padding">
        <div class="column">
          <div class="column-padding">
            <h3>About</h3>
            <p>IT professional with a focus on software development from New Zealand.</p>
          </div>
        </div>
        <div class="column">
          <div class="column-padding">
            <h3>Links</h3>
            <div class="pd-top-16px">
              <ul class="no-bullets pd-top-16px">
                <li>
                  <a href="https://draw.network" target="_blank">Draw Network</a>
                </li>
                <li>
                  <a href="https://corybeams.com/le/examples/" target="_blank">WebGL Lighting Engine</a>
                </li>
                <li>
                  <a href="https://mega.nz/#!w0NH2CzJ!Yxyd1aboH6rax9emClRl794jPjRGQASnJlgw3hEgSOc" target="_blank">Maze Pursuit Game</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="column-padding">
            <h3>Social Media</h3>
            <div class="social-media-container">
              <a href="https://github.com/Jaloko/" target="_blank" class="social-media-circle sm-github"></a>
              <a href="https://www.linkedin.com/in/cory-beams-1186b610b/" target="_blank" class="social-media-circle sm-linked-in"></a>
              <a href="https://twitter.com/nzjaloko" target="_blank" class="social-media-circle sm-twitter"></a>
              <a href="https://facebook.com/cory.beams" target="_blank" class="social-media-circle sm-facebook"></a> 
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-notice">© Cory Beams</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer {
    background-color: #273036;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  .footer .copyright-notice {
    color: #6c757d;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }

  .column {
    float: left;
    width: 33.33%;
  }

  .column-padding {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .social-media-container {
    margin-top: 16px;
  }

  .social-media-circle {
    display: inline-block;
    background-color: white;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 75px;
    -moz-border-radius: 75px;
    border-radius: 75px; 
    cursor: pointer;
  }

  .social-media-circle:hover {
    background-color: #5234eb;
    opacity: 1.0;
  }

  .social-media-circle:not(:first-child)  {
    margin-left: 10px;
  }

  .sm-github {
    background-image: url('/img/icons/github.svg');
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .sm-github:hover {
    background-image: url('/img/icons/github-hover.svg');
  }

  .sm-facebook {
    background-image: url('/img/icons/facebook.svg');
    background-size: 35px;
    background-position: left 7px bottom 6px;
    background-repeat: no-repeat;
  }

  .sm-facebook:hover {
    background-image: url('/img/icons/facebook-hover.svg');
  }

  .sm-twitter {
    background-image: url('/img/icons/twitter.svg');
    background-size: 35px;
    background-position: left 9px bottom 6px;
    background-repeat: no-repeat;
  }

  .sm-twitter:hover {
    background-image: url('/img/icons/twitter-hover.svg');
  }

  .sm-linked-in {
    background-image: url('/img/icons/linked-in.svg');
    background-size: 35px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .sm-linked-in:hover {
    background-image: url('/img/icons/linked-in-hover.svg');
  }

  @media screen and (max-width: 900px) {
    .column {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
</style>