<template>
  <div id="home" class="intro">
    <div class="intro-overlay">
      <div class="intro-content">
        <h1 class="intro-heading">Hi, I'm Cory</h1>
        <h2 class="intro-sub-heading">I develop software solutions</h2>
      </div>
      <div class="ocean">
        <div class="wave"></div>
      </div>
      <a href="#about" v-smooth-scroll class="down-arrow"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .intro-heading {
    margin: 0;
    margin-bottom: 5px;
  }

  .intro-sub-heading {
    margin: 0;
  }

  .intro {
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #0099FF;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .intro .intro-overlay {
    display: table;
    width: inherit;
    height: inherit;
    text-align: center;
    position: relative;
  }

  .intro .intro-overlay .intro-content {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 2;
  }

  .ocean-container {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .ocean { 
    width:100%;
    height: 10%;
    position: absolute;
    bottom:0;
    left:0;
    background: #21a6ff;
    z-index: 1;
  }

  .wave {
    background: url(/img/intro-wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }

  .down-arrow {
    position: absolute;
    width: 50px;
    height: 70px;
    line-height: 70px;
    background: url(/img/icons/down-arrow.png) no-repeat center;
    background-size: 70%;
    vertical-align: top;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @media screen and (max-width: 900px) {
    .down-arrow {

    }
  }
</style>