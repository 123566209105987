<template>
  <div>
    <NavBar/>
    <Intro/>
    <About />
    <Technology />
    <Contact />
    <Footer/>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import Intro from './Intro.vue';
import About from './About.vue';
import Technology from './Technology.vue';
import Contact from './Contact.vue';
import Footer from './Footer.vue';
export default {
  name: 'Page',
  components: {
    NavBar,
    Intro,
    About,
    Technology,
    Contact,
    Footer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
