<template>
  <div id="about" class="about">
    <div class="content-container content-padding">
      <h2>About</h2>
      <p class="large-p">I'm a {{this.getAge()}} year old IT professional with a focus on software development from New Zealand. I have a Bachelor of Computing from my studies at the Eastern Institute of Technology (EIT). I spend my spare time learning new technologies and software techniques. I also work on several software projects like this website. Aside from spending my time being a developer I like drinking coffee, playing video games, watching tv shows and hanging out with friends.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    getAge() {
      var today = new Date();
      var birthDate = new Date('1994-12-21');
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .about {
    width: 100%;
    color: #273036;
  }
</style>