<template>
  <div v-bind:class="positionNav()?'nav-bar no-scroll':'nav-bar has-scrolled'">
    <div v-bind:class="!mobileNav?'content-container pd-lr-20-px':'content-container'">
      <div class="nav-logo mgn-5-px h-50-px">
      </div>
      <div class="nav-text mgn-5-px h-50-px">
        Cory Beams
      </div>
      <div v-show="mobileNav" class="nav-menu" v-on:click="toggleMobileNav">
        <div v-bind:class="(!canShowMobileNav())?'menu-icon':'close-menu-icon'">
        </div>
      </div>
      <div id="nav" :class="getNavClass()">
        <div class="nav-menu-item h-50-px" v-for="(item, key) in navItems" :key="key">
          <a :class="isActive(item.url)" :href="item.url" v-on:click="closeMobileNav" v-smooth-scroll>{{item.name}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    msg: String
  },
  data: () => {
    return {
      initMobileNav: true,
      mobileNav: false,
      showMobileNav: false,
      bottomOfPage: false,
      topOfPage: true,
      activeNavItem: '',
      // When adding a new navItem need to add 50px to the full-height css rule
      navItems: [{ name: "Home", url: "#home"}, {name: "About", url: "#about"}, {name: "Technology", url: "#technology"}, {name: "Contact", url: "#contact"}] 
    };
  },
  methods: {
    getNavClass() {
      if(this.canShowMobileNav()) {
        return 'nav-transition full-height nav-menu mgn-5-px over';
      } else if(this.mobileNav && !this.showMobileNav && !this.initMobileNav) {
        return 'nav-transition zero-height nav-menu mgn-5-px';
      } else {
        return 'zero-height nav-menu mgn-5-px';
      }
    },
    positionNav() {
      return this.topOfPage && !this.mobileNav;
    },
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    closeMobileNav() {
      this.showMobileNav = false;
    },
    canShowMobileNav() {
      return (this.mobileNav && this.showMobileNav) || !this.mobileNav;
    },
    isActive(url) {
      if(url == this.activeNavItem) {
        return "active"
      } else {
        return "";
      }
    },
    scroll () {
      // Work out if we are at the top or bottom of the page
      if(window.pageYOffset == 0) {
        this.bottomOfPage = false;
        this.topOfPage = true;
      } else if((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        this.bottomOfPage = true;
        this.topOfPage = false;
      } else {
        this.bottomOfPage = false;
        this.topOfPage = false;
      }

      // Check for active nav item based on page scroll position.
      for(var i = this.navItems.length-1; i >= 0 ; i--) {
        let elementName = this.navItems[i].url.replace('#', '');
        if(this.topOfPage) {
          this.activeNavItem = '';
          break;
        }
        // 60 is the navbar height. Using .top as its supported by IE
        if(document.getElementById(elementName).getBoundingClientRect().top - 60 <= 0 || this.bottomOfPage) {
          this.activeNavItem = '#' + elementName;
          break;
        }
      }
    },
    resize() {
      if(window.innerWidth < 900) {
        this.mobileNav = true;
        // Purpose of initNav is to not show the css transition if resizing the webpage
        setTimeout(() => {
          if(this.mobileNav) this.initMobileNav = false;
        },200);
      } else {
        this.initMobileNav = true;
        this.mobileNav = false;
      }
    }
  },
  mounted () {
    this.scroll();
    this.resize();
    window.onscroll = () => {
      this.scroll();
    }
    window.onresize = () => {
      this.resize();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .nav-bar {
    display: block;
    position: fixed;
    width: 100%;
    height: 60px;
    text-align: left;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    z-index: 999;
  }

  .nav-bar.has-scrolled {
    padding-top: 0;
    padding-bottom: 0px;
    background-color: #5234eb;
    transition: linear .3s, all .3s, all .3s;
    -webkit-transition: linear .3s, all .3s, all .3s;
    box-shadow: 0 1px 3px rgba(0, 0, 0,0.3); 
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0,0.3); 
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0,0.3); 
  }

  .nav-bar.no-scroll {
    transition: linear .3s, all .3s, all .3s;
    -webkit-transition: linear .3s, all .3s, all .3s;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mgn-5-px {
    margin: 5px;
  }

  .pd-lr-20-px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .h-50-px {
    height: 50px;
    line-height: 50px;
  }

  .nav-logo {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: url(/img/logo/logo.png) no-repeat center;
    background-size: 100%;
    vertical-align: top;
  }

  .nav-text {
    display: inline-block;
    color: white;
    font-size: 2em;
    line-height: 55px;
  }

  .nav-menu {
    float: right;
    display: inline-block;
  }

  #nav {
    overflow: hidden;
  }

  .nav-transition {
     -webkit-transition: max-height 0.2s linear;
    -moz-transition: max-height 0.2s linear;
    -o-transition: max-height 0.2s linear;
    -ms-transition: max-height 0.2s linear;
    transition: max-height 0.2s linear;
  }

  .zero-height {
    max-height: 0;
  }

  /* max-height should be 50px per menu item. Unfortunately cant use height: 100% or the css transition doesnt work */
  .full-height {
    display: block !important;
    max-height: 200px;
  }

  .nav-menu .nav-menu-item {
    display: inline-block;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.1em;
  }

  .nav-menu .nav-menu-item a {
    color: inherit;
    text-decoration: none;
  }

  .nav-menu .nav-menu-item a:hover {
    opacity: 0.5;
  }

  .nav-menu .nav-menu-item a.active {
    color: #21a6ff;
  }

  .menu-icon {
    margin: 15px;
    width: 30px;
    height: 30px;
    background: url(/img/icons/menu.png);
    background-size: 100%;
    background-position: center;
    cursor: pointer;
  }

  .close-menu-icon {
    margin: 15px;
    width: 30px;
    height: 30px;
    background: url(/img/icons/close-menu.png);
    background-size: 100%;
    background-position: center;
    cursor: pointer;
  }

  @media screen and (max-width: 900px) {
    #nav {
      display: block;
      float: none;
      background-color: #5234eb;
      margin: 0;
    }

    .nav-menu .nav-menu-item{
      display: block;
      position: relative;
      text-align: center;
    }
  }
</style>
