<template>
  <div id="app">
    <div class="preload"></div>
    <Page/>
  </div>
</template>

<script>
import Page from './components/Page.vue';

export default {
  name: 'App',
  components: {
    Page
  }
}
</script>

<style>
/*  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap');*/
  /* Backup local font definitions incase the gopogle font import goes offline */
  /* vietnamese */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUAnx4RHw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUA3x4RHw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Josefin_Sans/Qw3aZQNVED7rKGKxtqIqX5EUDXx4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html, body {
    margin: 0;
    padding: 0;
    font-family: Open Sans,Tahoma,sans-serif;
    background-color: rgb(243, 244, 245);
    font-weight: 400;
    overflow-x: hidden;
  }

  a {
    color: #5234eb;
    text-decoration: none;
  }

  a:hover {
    color: #7f6aeb;
  }

  ul.no-bullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    font-family: Open Sans,Tahoma,sans-serif;
    overflow-x: hidden;
  }

  .content-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    box-sizing: border-box;
  }

  .content-padding {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h1, h2, h3, h4, h5 {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 4.5em;
  }

  h2 {
    font-size: 3em;
    padding-bottom: 20px;
  }

  .large-p {
    font-size: 1.4em;
  }

  p:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .pd-top-16px {
    padding-top: 16px;
  }

  .preload {
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: url("/img/icons/menu.png") url("/img/icons/close-menu.png") url("/img/icons/twitter.svg") url("/img/icons/twitter-hover.svg") url("/img/icons/linked-in.svg") url("/img/icons/linked-in-hover.svg") url("/img/icons/github.svg") url("/img/icons/github-hover.svg") url("/img/icons/facebook.svg") url("/img/icons/facebook-hover.svg") url("/img/icons/email.png") url("/img/icons/email-hover.png");
  }

</style>
